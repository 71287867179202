import { useState } from "react";
export const Employee = ({formData, handleFieldChange, handleFileChange}) => {
    return (
        <>
            <div className="employee">
                <div className="inptBlock mt-2 col-md-5">
                    <label htmlFor="Name">Previous Employer:</label>
                    <input className="inpt" type="text" name="companyName" value={formData.companyName} onChange={handleFieldChange} />
                </div>
                <div className="inptBlock mt-2 col-md-5">
                    <label htmlFor="Name">Company Website:</label>
                    <input className="inpt" type="text" name="website" value={formData.website} onChange={handleFieldChange} />
                </div>
                <div className="inptBlock col-md-5">
                    <label htmlFor="Name">Previous Designation:</label>
                    <input className="inpt" type="text" name="previousDesignation" value={formData.previousDesignation} onChange={handleFieldChange} />
                </div>
                <div className="inptBlock col-md-5">
                    <label htmlFor="Name">Years of Employment</label>
                    <input className="inpt" type="text" name="yearsOfEmployment" value={formData.yearsOfEmployment} onChange={handleFieldChange} />
                </div>
                <div className="inptBlock col-md-5"></div>
                <div className="inptBlock textField d-block">
                    <label htmlFor="Name">Message:</label>
                    <textarea className="inpt" type="text" name="message" value={formData.message} onChange={handleFieldChange} />
                </div>
                <div className="inptBlock col-md-5">
                    <label htmlFor="Name">Upload Resume</label>
                    <input className="fileUploadBlck" type="file" onChange={handleFileChange} />
                </div>
                <div className="inptBlock col-md-5"></div>
            </div>
        </>
    )
}