import { useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import { Header } from "./Header.js";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Footer } from "./Footer.js";
import './services.css'
import './media.css'
import './industries.css'
import ServiceTypes from "./ServiceTypes.jsx";
import Cards from "./Cards.jsx"
import { Testimonial } from "./Testimonial.js";

export const Media_Entertainment = () => {
    useEffect(() => {
        AOS.init();
        document.title = "Right Careers - media and entertainment industry";
    }, [])

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const ref = useRef()

    return (
        <>
            <Header />
            {/* banner section */}
            <div className="bg-img services_bg_media">
                <div className="bdcrumb"><a href="/">Home</a> | Industries | Media & Entertainment</div>
                <span className="sm-highlight">Media & Entertainment</span>
                <h1 data-aos="fade-right" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine" className="headline-text text-white">Exploring Beyond the Screen,  <br /><span className="subhead" style={{ fontWeight: 400 }}>where Talent Shines</span></h1>
                <div className="text-center custom-icon"><IoIosArrowDown onClick={handleClick} className='custom-typography' /></div>
            </div>
            {/* about overview section on home page */}
            <section className="clients my-5" ref={ref}>
                <div className="industry headings">
                    <h2 data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="500" style={{ fontWeight: 800 }} className="mx-auto industrial mytext">The media and entertainment industry is projected to reach $2.6 trillion by 2025, with an estimated 1.9 million new jobs expected to be created globally. However, the sector struggles with a 30% turnover rate and requires 45% of its workforce to upskill due to rapid technological advancements, making strategic recruitment essential for sustainable growth.</h2>
                    <p data-aos="fade-up" data-aos-duration="500" className="mt-3">The Media and Entertainment industry is a vibrant and ever-evolving sector that thrives on creativity, innovation, and technology. At Right Careers, we understand the unique hiring challenges faced by this dynamic field. We specialize in connecting organizations with top talent across various roles, from digital marketing experts and social media managers to game developers and video production professionals. Our tailored recruitment solutions ensure that companies find individuals who not only possess the necessary skills but also share a passion for storytelling and audience engagement. Let us help you build a talented team that drives your creative vision forward.</p>
                </div>
            </section>
            <section className="indSrvc pt-5">
                <h3 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" className="text-center sectTitle">Our Services</h3>
                <div className="container pt-4">
                    <div className="row">
                        <div className="col-md-6">
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon1.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Talent Acquisition</h5>
                                    <p>Identifying and attracting top candidates to fit organizational roles.&nbsp;&nbsp;</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon2.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Executive Search</h5>
                                    <p>Locating senior-level leaders with vision and strategic expertise.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon3.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Temporary Staffing</h5>
                                    <p>Providing flexible, short-term talent solutions for immediate business needs.</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon4.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Recruitment Process 
                                    Outsourcing (RPO)</h5>
                                    <p>Managing the entire recruitment process, ensuring efficiency and quality hires.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 right  rghtSect">
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon5.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Employer Branding</h5>
                                    <p>Enhancing company reputation to attract and retain top talent.</p>
                                </div>
                            </div>
                            <img data-aos="fade-right" data-aos-duration="1000" className="lady" src={require('./img/industrial_serivce.webp')} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="staffing py-5">
                <h3 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" className="text-center sectTitle">Our Staffing Solutions</h3>
                <div className="container py-4">
                    <div className="cards">
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/media1.jpg')} alt="" />
                            <div className="innertexts">
                                <h5>Digital Marketing & Social Media</h5>
                                <p>
                                    - Social Media Strategist<br />
                                    - SEO Specialist<br />
                                    - Digital Marketing Director<br />
                                    - Influencer Partnership Manager
                                </p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/media2.jpg')} alt="" />
                            <div className="innertexts">
                                <h5>Video Production & Editing</h5>
                                <p>
                                    - Video Production Specialist<br />
                                    - Post-Production Editor<br />
                                    - Multimedia Content Creator
                                </p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/media3.jpg')} alt="" />
                            <div className="innertexts">
                                <h5>Video Games & Interactive Entertainment</h5>
                                <p>
                                    - Game Design Architect<br />
                                    - Game Development Engineer<br />
                                    - Quality Assurance Game Tester
                                </p>
                            </div>
                        </div>
                        {/* <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./img/staffing1.webp')} alt="" />
                            <div className="innertexts">
                                <h5>Engineering Roles</h5>
                                <p>
                                    - Automobile Design Engineer<br />
                                    - Vehicle Dynamics Engineer<br />
                                    - Electrical and Electronics Engineer<br />
                                    - Powertrain Engineer<br />
                                    - Mechanical Engineer<br />
                                    - Battery Engineer (for EVs)
                                </p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            <section className="clients py-5">
                <h3 data-aos="fade-up" data-aos-easing="ease-in-sine" className="text-center sectTitle">Some of Our Clients</h3>
                <div className="text-center">
                    <img data-aos="zoom-in" data-aos-duration="1000" className="pt-3" style={{ width: 300 }} src={require('./img/tatamotors.webp')} alt="" />
                </div>
            </section>
            <Testimonial />
            <section className="indstats">
                <video className='visionBg' autoPlay muted loop>
                    <source src={require('./video/Indvid.mp4')} type="video/mp4" />
                </video>
                <div className="visionContent indRows py-5">
                    <p data-aos="fade-right" data-aos-duration="500"><span className="boldText">5K+</span> Successful<br /> Recruits Yearly</p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="1000">Assured <span className="boldText">2:2</span><br />Hiring Plan </p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="1500">Consistent Track record of<br /><span className="boldText">95%</span> On-Time Delivery</p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="2000" className="nobr"><span className="boldText">Skill-Based Training</span><br /> with Certified Industry Experts</p>
                </div>
            </section>
            <Footer />
        </>
    )
}