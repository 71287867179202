import { useEffect, useRef, useState } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { IoIosArrowDown, IoIosSearch } from "react-icons/io";
import { TfiArrowCircleRight } from "react-icons/tfi";
import './about.css'
import { Nizel } from "./Nizel";
import { Hemant } from "./Hemant";
import { Sonali } from "./Sonali";
import { Timeline } from "./Timeline";
import { Map } from './Map';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";

export const AboutOverview = () => {
    useEffect(() => {
        document.title = "Right Careers - about us";
        const metaDescription = document.querySelector("meta[name='description']");
        console.log("Before update:", metaDescription?.content);
        if (metaDescription) {
          metaDescription.setAttribute("content", "Welcome to the home page.");
          console.log("After update:", metaDescription?.content);
        }
      }, []);

    useEffect(() => {
        AOS.init();
    }, [])

    const ref = useRef()
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
    const [modalShow, setModalShow] = useState(false);
    const [leader, setLeader] = useState();

    return (
        <>
            <Header />
            <div className="banner bg-img-about d-none d-md-flex">
                <div className="bdcrumb"><Link to="/">Home</Link> | <Link to="/about-overview">About</Link> | Overview</div>
                <span className="sm-highlight">Overview</span>
                <h1 data-aos="fade-right" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine" className="headline-text text-white">Empowering Talent, <br /><span style={{ fontWeight: 400 }}>Transforming Futures,</span> Together</h1>
                <div className="text-center custom-icon"><IoIosArrowDown onClick={handleClick} className='custom-typography' /></div>
            </div>
            {/* Mob */}
            <div className="banner bg-img-about d-sm-block d-md-none">
                <div className="bdcrumb"><Link to="/">Home</Link> | <Link to="/about-overview">About</Link> | Overview</div>
                <span className="sm-highlight">Overview</span>
                <h1 data-aos="fade-right" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine" className="headline-text text-white">Empowering Talent, <br /><span style={{ fontWeight: 400 }}>Transforming Futures,</span> Together</h1>
                <div className="text-center custom-icon"><IoIosArrowDown onClick={handleClick} className='custom-typography' /></div>
            </div>
            {/* Mob End */}
            <section data-aos-easing="ease-in-sine" ref={ref} className="story my-4 mb-5">
                <h3 data-aos="fade-up" className="text-center py-3 pb-4 mb-4 sectTitle">Our Story</h3>
                <div className="container d-none d-md-flex">
                    <div className="row">
                        <div data-aos="fade-left" data-aos-duration="1000" className="col-md-5 lftSect d-flex">
                            <p className="lftTitle align-self-center">Right Careers is a global IT and Non-IT Talent & Skills Management leader, connecting top talent with industry-leading organizations across the US, UK, UAE, India, and New Zealand.</p>
                        </div>
                        <div data-aos="fade-right" data-aos-duration="1500" className="col-md-7 rghtSect">
                            <p>We specialize in providing immediate access to highly skilled professionals in in-demand tech domains such as Microsoft, SAP, Salesforce, Oracle, and Cybersecurity.<br /><br />

                                As a subsidiary of Azucane IT Solutions LLC, UAE, we are headquartered in Mumbai, bringing the best of talent and digital technology solutions together. Our strategic partnership with Azucane bridges the gap between technology and talent, using advanced tools like AI-powered candidate matching and analytics-driven talent management.<br /><br />

                                At Right Careers, we don’t just place candidates; we focus on career development through training programs and workshops to ensure continuous upskilling in the latest IT trends.<br /><br />

                                We’re committed to empowering professionals and organizations to thrive in today’s ever-evolving digital landscape.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container d-sm-block d-md-none">
                    <div className="row">
                        <div data-aos="fade-bottom" data-aos-duration="1000" className="col-md-5 lftSect d-flex">
                            <p className="lftTitle align-self-center">Right Careers is a global IT and Non-IT Talent & Skills Management leader, connecting bottom talent with industry-leading organizations across the US, UK, UAE, India, and New Zealand.</p>
                        </div>
                        <div data-aos="fade-bottom" data-aos-duration="1500" className="col-md-7 rghtSect">
                            <p>We specialize in providing immediate access to highly skilled professionals in in-demand tech domains such as Microsoft, SAP, Salesforce, Oracle, and Cybersecurity.<br /><br />

                                As a subsidiary of Azucane IT Solutions LLC, UAE, we are headquartered in Mumbai, bringing the best of talent and digital technology solutions together. Our strategic partnership with Azucane bridges the gap between technology and talent, using advanced tools like AI-powered candidate matching and analytics-driven talent management.<br /><br />

                                At Right Careers, we don’t just place candidates; we focus on career development through training programs and workshops to ensure continuous upskilling in the latest IT trends.<br /><br />

                                We’re committed to empowering professionals and organizations to thrive in today’s ever-evolving digital landscape.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <seciton className="vision d-flex flex-column">
                <video className='visionBg' autoPlay muted loop>
                    <source src={require('./video/vision.mp4')} type="video/mp4" />
                </video>
                <div className="visionContent">
                    <h3 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" className="text-center pb-4 sectTitle">Our Vision</h3>
                    <h4 data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-sine">Your Global Ally in Unleashing Exceptional Talent.</h4>
                </div>
            </seciton>
            <section className="cardSect py-5">
                <h3 className="text-center pb-4 mb-3 sectTitle">Leadership</h3>
                <div className="cardContainer container">
                    <div data-aos="fade-down" data-aos-offset="300" data-aos-duration="300" data-aos-easing="ease-in-sine" className="card card1">
                        <img src={require('./img/Leaders/nizel.webp')} alt="" />
                        <h4>Nizel Jacinto</h4>
                        <h6>Founder and CEO</h6>
                        <p className="ctaPop" onClick={() => { setModalShow(true); setLeader("Nizel") }}><TfiArrowCircleRight className="ctaPopSvg" /> View Profile</p>
                        {leader === "Nizel" ? <Nizel show={modalShow} onHide={() => setModalShow(false)} /> : ""}
                        {console.log(leader)}
                    </div>
                    <div data-aos="fade-down" data-aos-offset="300" data-aos-duration="500" data-aos-easing="ease-in-sine" className="card card2">
                        <img src={require('./img/Leaders/hemant.webp')} alt="" />
                        <h4>Hemant Pawar</h4>
                        <h6>Sr. Vice President</h6>
                        <p className="ctaPop" onClick={() => { setModalShow(true); setLeader("Hemant") }}><TfiArrowCircleRight className="ctaPopSvg" /> View Profile</p>
                        {leader === "Hemant" ? <Hemant show={modalShow} onHide={() => setModalShow(false)} /> : ""}
                    </div>
                    <div data-aos="fade-down" data-aos-offset="300" data-aos-duration="800" data-aos-easing="ease-in-sine" className="card card3">
                        <img src={require('./img/Leaders/sonali.webp')} alt="" />
                        <h4>Sonali Kamath</h4>
                        <h6>CHRO</h6>
                        <p className="ctaPop" onClick={() => { setModalShow(true); setLeader("Sonali") }}><TfiArrowCircleRight className="ctaPopSvg" /> View Profile</p>
                        {leader === "Sonali" ? <Sonali show={modalShow} onHide={() => setModalShow(false)} /> : ""}
                    </div>
                </div>
            </section>
            <section className="partnership text-center py-5">
                <div className="container">
                    <h2 data-aos="fade-down">We Simplify Partnerships to Amplify Impact.</h2>
                    <div className="mt-5 partners" data-aos="fade-up" data-aos-duration="1000">
                        <img src={require('./img/partners/logo1.png')} alt="Right Careers Patners" />
                        <img src={require('./img/partners/logo2.png')} alt="Right Careers Patners" />
                        <img className="pe-0" src={require('./img/partners/logo3.png')} alt="Right Careers Patners" />
                        <img className="ps-0" src={require('./img/partners/logo4.png')} alt="Right Careers Patners" /><br />
                        <img src={require('./img/partners/logo5.png')} alt="Right Careers Patners" />
                        <img src={require('./img/partners/logo6.png')} alt="Right Careers Patners" />
                        <img src={require('./img/partners/logo7.png')} alt="Right Careers Patners" />
                    </div>
                </div>
            </section>
            <Timeline />
            <Map />
            <Footer />
        </>
    )
}