import { MdLocationPin } from "react-icons/md";
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoIosMail } from "react-icons/io";
import { ReactComponent as Facebook } from './img/svg/facebook_logo_icon.svg'
import { ReactComponent as Instagram } from './img/svg/instagram_logo_icon.svg'
import { ReactComponent as Youtube } from './img/svg/youtube_videos_icon.svg'
import { ReactComponent as Linkedin } from './img/svg/linkedin_logo_icon.svg'
import Pdf1 from './pdfs/Corporate_Stewardship_Policy.pdf';
import Pdf2 from './pdfs/Data Privacy Framework.pdf';
import Pdf3 from './pdfs/Environment Safety Policy.pdf';
import Pdf4 from './pdfs/Gifts, Hospitality and Ethical Conduct.pdf';
import Pdf5 from './pdfs/Operational Resilience Policy.pdf';
import Pdf6 from './pdfs/Workplace Dignity and Respect Policy.pdf';

export const Footer = () => {
    return (
        <>
            <section className="footer">
                <div className="container py-3">
                    <div className="row gy-4">
                        {/* Company Info */}
                        <div className="col-md-4 informative cs1">
                            <img
                                alt='Client Logo'
                                src={require("./img/right_career_logo_footer.webp")}
                                className="img-fluid mb-3"
                                style={{ maxWidth: '200px' }}
                            />
                            <div className="location mt-3 d-flex align-items-start">
                                <span className="pe-2">
                                    <MdLocationPin className="icons" style={{ fontSize: 24 }} />
                                </span>
                                <span className="small">
                                    MBC Infotech Park, Office No. 112,<br />
                                    1st Floor, D-Wing. Ghodbunder Road, Kasarvadavli,<br />
                                    Thane 400615, Maharashtra, India.
                                </span>
                            </div>
                            <div className="location mt-3 d-flex align-items-center">
                                <span className="pe-2">
                                    <BiSolidPhoneCall className="icons" style={{ fontSize: 24 }} />
                                </span>
                                <span>
                                    <a href="tel:+91 9307416089" className="text-decoration-none">+91 9307416089</a>
                                </span>
                            </div>
                            <div className="location mt-3 d-flex align-items-center">
                                <span className="pe-2">
                                    <IoIosMail className="icons" style={{ fontSize: 24 }} />
                                </span>
                                <span>
                                    <a href="mailto:hr@rightcareers.co.in" className="text-decoration-none">hr@rightcareers.co.in</a>
                                </span>
                            </div>
                        </div>

                        {/* Quick Links and Industries - Hidden on mobile */}
                        <div className="col-md-4 d-none d-md-flex cs2">
                                <div className="col-4">
                                    <p className="mb-2 fw-bold">Quick Links</p>
                                    <ul className="list-unstyled links small">
                                        <li><a href='/' className="text-decoration-none d-inline-block mb-1">Home</a></li>
                                        <li><a href='/about-overview' className="text-decoration-none d-inline-block mb-1">Overview</a></li>
                                        <li><a href='/join-us' className="text-decoration-none d-inline-block mb-1">Join Us</a></li>
                                        <li><a href='/connect-with-us' className="text-decoration-none d-inline-block mb-1">Connect with Us</a></li>
                                        <li><a href='/services' className="text-decoration-none d-inline-block mb-1">Services</a></li>
                                    </ul>
                                </div>
                                <div className="col-4">
                                    <p className="mb-2 fw-bold br_title">Industries</p>
                                    <ul className="list-unstyled links small br_right">
                                        <li><a href='/automobile' className="text-decoration-none d-inline-block mb-1">Automobile</a></li>
                                        <li><a href='/banking' className="text-decoration-none d-inline-block mb-1">Banking</a></li>
                                        <li><a href='/energy_infrastructure' className="text-decoration-none d-inline-block mb-1">Energy</a></li>
                                        <li><a href='/financial_professional' className="text-decoration-none d-inline-block mb-1">Financial</a></li>
                                        <li><a href='/healthcare' className="text-decoration-none d-inline-block mb-1">Healthcare</a></li>
                                        <li><a href='/hospitality' className="text-decoration-none d-inline-block mb-1">Hospitality</a></li>
                                    </ul>
                                </div>
                                <div className="col-4">
                                    <p className="mb-2 fw-bold"></p>
                                    <ul className="list-unstyled links small br_next">
                                        <li><a href='/industrial_engineering' className="text-decoration-none d-inline-block mb-1">Engineering</a></li>
                                        <li><a href='/information_technology' className="text-decoration-none d-inline-block mb-1">IT</a></li>
                                        <li><a href='/lifestyle' className="text-decoration-none d-inline-block mb-1">Lifestyle</a></li>
                                        <li><a href='/logistics' className="text-decoration-none d-inline-block mb-1">Logistics</a></li>
                                        <li><a href='/media_entertainment' className="text-decoration-none d-inline-block mb-1">Media</a></li>
                                    </ul>
                                </div>
                        </div>

                        {/* Policies and Social Media - Hidden on mobile */}
                        <div className="col-md-4 d-none d-md-block cs3">
                            <p className="mb-2 fw-bold">Policies For Right Careers</p>
                            <ul className="list-unstyled links small">
                                <li><a href={Pdf1 + "#toolbar=0"} target="_blank" className="text-decoration-none d-inline-block mb-1">Corporate Stewardship Policy</a></li>
                                <li><a href={Pdf2 + "#toolbar=0"} target="_blank" className="text-decoration-none d-inline-block mb-1">Data Privacy Framework</a></li>
                                <li><a href={Pdf3 + "#toolbar=0"} target="_blank" className="text-decoration-none d-inline-block mb-1">Environment Safety Policy</a></li>
                                <li><a href={Pdf4 + "#toolbar=0"} target="_blank" className="text-decoration-none d-inline-block mb-1">Gifts & Ethical Conduct</a></li>
                                <li><a href={Pdf5 + "#toolbar=0"} target="_blank" className="text-decoration-none d-inline-block mb-1">Operational Resilience</a></li>
                                <li><a href={Pdf6 + "#toolbar=0"} target="_blank" className="text-decoration-none d-inline-block mb-1">Workplace Dignity Policy</a></li>
                            </ul>

                            <div className="socialMedia mt-4">
                                <p className="mb-2 fw-bold">Follow Us</p>
                                <div className="socialIcons d-flex gap-3">
                                    <a target="_blank" href="https://www.linkedin.com/company/right-careers-in/" className="text-decoration-none">
                                        <Linkedin className="socialmediaIcon" />
                                    </a>
                                    <a target="_blank" href="https://www.facebook.com/rightcareersoffical?mibextid=ZbWKwL" className="text-decoration-none">
                                        <Facebook className="socialmediaIcon" />
                                    </a>
                                    <a target="_blank" href="https://www.instagram.com/rightcareers?igsh=MTNzeGttYTFzMWVtNw" className="text-decoration-none">
                                        <Instagram className="socialmediaIcon" />
                                    </a>
                                    <a target="_blank" href="https://www.youtube.com/@Right.Careers" className="text-decoration-none">
                                        <Youtube className="socialmediaIcon" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Copyright Section */}
                <div class="d-none d-md-block" style={{backgroundColor: '#fff' }}>
                    <div class="copyright my-2 container">
                        <p class="mb-0">© Right Careers 2024. All Rights Reserved.</p>
                        <p class="mb-0 internlinks"><a href="/terms-and-conditions">T &amp; C</a> | <a href="/privacy-policy">Privacy Policy</a> | <a href="/cookies-policy">Cookies Policy</a> | <a href="/disclaimer">Disclaimer</a></p>
                        <p class="mb-0" style={{color: '#545454' }} >Powered by <a href="https://designcentric.co.in" target="_blank">Designcentric</a></p>
                    </div>
                </div>
                <div class="d-sm-block d-md-none" style={{backgroundColor: '#fff' }}>
                    <div class="copyright my-2 container">
                        <p class="mb-0 internlinks"><a href="/terms-and-conditions">T &amp; C</a> | <a href="/privacy-policy">Privacy Policy</a> | <a href="/cookies-policy">Cookies Policy</a> | <a href="/disclaimer">Disclaimer</a></p>
                        <p class="mb-0">© Right Careers 2024. All Rights Reserved.</p>
                        <p class="mb-0" style={{color: '#545454' }} >Powered by <a href="https://designcentric.co.in" target="_blank">Designcentric</a></p>
                    </div>
                </div>
            </section>
        </>
    );
}