export const Employer = ({formData, handleFieldChange}) => {
    return (
        <>
            <div className="employee">
                <div className="inptBlock mt-2 col-md-5">
                    <label htmlFor="Name">Company Name</label>
                    <input className="inpt" type="text" name="companyName" value={formData.companyName} onChange={handleFieldChange} />
                </div>
                <div className="inptBlock mt-2 col-md-5">
                    <label htmlFor="Name">Website</label>
                    <input className="inpt" type="text" name="website" value={formData.website} onChange={handleFieldChange} />
                </div>
                <div className="inptBlock col-md-5">
                    <label htmlFor="Name">No. of employees in your company</label>
                    <select className="inpt" name="numInComp" value={formData.numInComp} onChange={handleFieldChange}>
                        <option>Please Select</option>
                        <option value='1 – 50'>1 – 50</option>
                        <option value='51 -100'>51 -100</option>
                        <option value='101 – 200'>101 – 200</option>
                        <option value='201 – 500'>201 – 500</option>
                        <option value='501 – 1000'>501 – 1000</option>
                    </select>
                </div>
                <div className="inptBlock col-md-5">
                    <label htmlFor="Name">No. of Employees to be Recruited</label>
                    <input className="inpt" type="text" name="numToRecruit" value={formData.numToRecruit} onChange={handleFieldChange} />
                </div>
                <div className="inptBlock col-md-5">
                    <label htmlFor="Name">Type of Resources to be Hired:
                    </label>
                    <select className="inpt" name="resourceType" value={formData.resourceType} onChange={handleFieldChange}>
                        <option>Please Select</option>
                        <option value='Top level'>Top level</option>
                        <option value='Mid Level'>Mid Level</option>
                        <option value='Entry Level'>Entry Level</option>
                        <option value='combination of Levels'>combination of Levels</option>
                    </select>
                </div>
                <div className="inptBlock col-md-5"></div>
                <div className="inptBlock textField">
                    <label htmlFor="Name">Message</label>
                    <textarea className="inpt" type="text" name="message" value={formData.message} onChange={handleFieldChange}/>
                </div>
            </div>
        </>
    )
}