import { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import { Header } from "./Header";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './joinus.css';
import BasicExample from "./BasicExample.jsx";
import { Footer } from "./Footer";
import { Map } from "./Map.js";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Employee } from "./Employee.jsx";
import { Employer } from "./Employer.jsx";
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import { FaSpinner } from "react-icons/fa";

export const Connect = () => {
    // useState variable for phone field top side of the form
    const [captchaValue, setCaptchaValue] = useState(null);
    const [value, setValue] = useState()
    // useState for whether it is employer or employee
    const [user, setUser] = useState()
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        contactNumber: '',
        emailId: '',
        connectAs: '',
        companyName: '',
        numInComp: '',
        resourceType: '',
        website: '',
        yearsOfEmployment: '',
        numToRecruit: '',
        message: '',
        previousDesignation: '',
        file: null,
    });

    useEffect(() => {
        AOS.init();
        document.title = "Right Careers - connect with us";
    }, [])
    
    const [responseMessage, setResponseMessage] = useState('');

    const handleFieldChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };


    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const handlePhoneChange = (value) => {
        setFormData({ ...formData, contactNumber: value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, file: e.target.files[0] });
    };

    const ref = useRef()

    const handleSubmit = async (e) => {
        e.preventDefault()
        // if (!captchaValue) {
        //     alert("Please verify the CAPTCHA");
        //     return; 
        // }
        setLoading(true);
        const formDataToSend = new FormData();
        formDataToSend.append('firstName', formData.firstName);
        formDataToSend.append('lastName', formData.lastName);
        formDataToSend.append('contactNumber', formData.contactNumber);
        formDataToSend.append('emailId', formData.emailId);
        formDataToSend.append('connectAs', formData.connectAs);
        if (formData.connectAs == "Job Seeker") {
            formDataToSend.append('companyName', formData.companyName);
            formDataToSend.append('website', formData.website);
            formDataToSend.append('yearsOfEmployment', formData.yearsOfEmployment);
            formDataToSend.append('previousDesignation', formData.previousDesignation);
            formDataToSend.append('message', formData.message);
            if (formData.file) {
                formDataToSend.append('file', formData.file);
            }
        }

        if (formData.connectAs == "Employer") {
            formDataToSend.append('companyName', formData.companyName);
            formDataToSend.append('website', formData.website);
            formDataToSend.append('numToRecruit', formData.numToRecruit);
            formDataToSend.append('numInComp', formData.numInComp);
            formDataToSend.append('resourceType', formData.resourceType);
            formDataToSend.append('message', formData.message);
        }

        try {
            const response = await axios.post('https://rc-red.vercel.app/api/submit-form', formDataToSend, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            setResponseMessage('Form submitted successfully!');
            if (response.status === 200) {
                setResponseMessage('Form submitted successfully! Thank you for applying.');
                setFormData({
                    firstName: '',
                    lastName: '',
                    contactNumber: '',
                    emailId: '',
                    connectAs: '',
                    companyName: '',
                    numInComp: '',
                    resourceType: '',
                    website: '',
                    yearsOfEmployment: '',
                    numToRecruit: '',
                    message: '',
                    previousDesignation: '',
                    file: null,
                });
            } else {
                setResponseMessage('Something went wrong. Please try again later.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setResponseMessage('Server error. Please try again.');
        }

        finally {
            setLoading(false); // Stop loading after API call
        }

        // Send data to backend
        // console.log("CAPTCHA Verified:", captchaValue);
    }

    // Select field value //

    const handleChange = (e) => {
        setUser(e.target.value);  // Keep track of selected user type
        setFormData({ ...formData, connectAs: e.target.value }); // Ensure formData also updates
    }

    return (
        <>
            <Header />
            <div className="banner bg-img-connect">
                <div className="bdcrumb"><Link to="/">Home</Link> | About | Connect with Us</div>
                <span className="sm-highlight">Connect with Us</span>
                <h1 data-aos="fade-right" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine" className="headline-text text-white">Looking to connect? <br /><span className="subhead" style={{ fontWeight: 400 }}>Here’s how we can assist you</span></h1>
                <div className="text-center custom-icon"><IoIosArrowDown onClick={handleClick} className='custom-typography' /></div>
            </div>
            <section ref={ref} className='p-5 w80 connectSect'>
                <h3 className="text-center sectTitle pb-5">Corporate Office</h3>
                <div className="container">
                    <div className="presence">
                        <div data-aos="fade-right" data-aos-duration="2000" className="block block1">
                            <h5 className="title">Mumbai Office</h5>
                            <p className="address">Office No. 112, 1st Floor,
                                D-Wing MBC Infotech Park,<br /> Ghodbunder Road,
                                Kasarvadavli, Thane – 400615.<br /> Maharashtra, India.
                            </p>
                        </div>
                        <div data-aos="fade-right" data-aos-duration="2000" className="block block2">
                            <h5 className="title">Connect us</h5>
                            <p className="connectAddress">
                                <span className="">Tel:</span> 022 -48016256 / <a href="tel:9307416089">9307416089</a><br />
                                <span className="">Email:</span><a href="mailto:sales@rightcareers.co.in"> sales@rightcareers.co.in</a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="connectForm py-4 d-none d-md-block">
                <h3 data-aos="fade-up" className="text-center sectTitle pb-5">Contact Us</h3>
                <div className="container">
                    <form className="contact" data-aos="zoom-in" data-aos-duration="2000" onSubmit={handleSubmit}>
                        <div className="upperFrmSect">
                            <div className="inptBlock col-md-5">
                                <label htmlFor="Name">First Name</label>
                                <input className="inpt" type="text" name="firstName" value={formData.firstName} onChange={handleFieldChange} required />
                            </div>
                            <div className="inptBlock col-md-5">
                                <label htmlFor="Name">Last Name</label>
                                <input className="inpt" type="text" name="lastName" value={formData.lastName} onChange={handleFieldChange} required />
                            </div>
                            <div className="inptBlock col-md-5">
                                <label htmlFor="Name">Contact Number</label>
                                <PhoneInput
                                    country={'us'}
                                    international
                                    defaultCountry="IN"
                                    value={formData.contactNumber}
                                    onChange={handlePhoneChange}
                                    required
                                />
                            </div>
                            <div className="inptBlock col-md-5">
                                <label htmlFor="Name">Email Id</label>
                                <input className="inpt" type="email" name="emailId" value={formData.emailId} onChange={handleFieldChange} />
                            </div>
                        </div>
                        <div className="userSect py-3">
                            <div className="inptBlock col-md-5">
                                <label htmlFor="Name">You wish to connect as*</label>
                                <select className="inpt" name="connectAs" value={formData.connectAs} onChange={(e) => { handleChange(e); handleFieldChange(e) }}>
                                    <option>Please Select</option>
                                    <option value='Job Seeker'>Job Seeker</option>
                                    <option value='Employer'>Employer</option>
                                </select>
                            </div>
                            <div className="inptBlock col-md-5 mt-4"></div>
                            {/* {console.log('current value of selected stuff ' + user)} */}
                            {formData.connectAs === "Job Seeker" ? <Employee formData={formData} handleFieldChange={handleFieldChange} handleFileChange={handleFileChange} /> : ""}
                            {formData.connectAs === "Employer" ? <Employer formData={formData} handleFieldChange={handleFieldChange} /> : ""}

                            <div className="recaptcha">
                                <ReCAPTCHA
                                    sitekey="6LdkxdUqAAAAAA-qfIv8E4HZkD74nfCgXdtz-Heq"
                                    onChange={(value) => setCaptchaValue(value)}
                                />
                            </div>
                            <div className="text-center submt">
                                <button className="submitBtn" type="submit" disabled={loading}>Submit {loading && <FaSpinner className="spinner-icon" />}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
            <section className="connectForm py-4 d-sm-block d-md-none">
                <h3 data-aos="fade-up" className="text-center sectTitle pb-5">Contact Us</h3>
                <div className="container">
                    <form data-aos="zoom-in" data-aos-duration="2000" onSubmit={handleSubmit}>
                        <div className="upperFrmSect">
                            <div className="inptBlock col-md-5">
                                <label htmlFor="Name">First Name</label>
                                <input className="inpt" type="text" name="firstName" value={formData.firstName} onChange={handleFieldChange} required />
                            </div>
                            <div className="inptBlock col-md-5">
                                <label htmlFor="Name">Last Name</label>
                                <input className="inpt" type="text" name="lastName" value={formData.lastName} onChange={handleFieldChange} required />
                            </div>
                            <div className="inptBlock col-md-5">
                                <label htmlFor="Name">Contact Number</label>
                                <PhoneInput
                                    country={'us'}
                                    international
                                    defaultCountry="IN"
                                    value={formData.contactNumber}
                                    onChange={handlePhoneChange}
                                    required
                                />
                            </div>
                            <div className="inptBlock col-md-5">
                                <label htmlFor="Name">Email Id</label>
                                <input className="inpt" type="email" name="emailId" value={formData.emailId} onChange={handleFieldChange} />
                            </div>
                        </div>
                        <div className="userSect py-3">
                            <div className="inptBlock col-md-5">
                                <label htmlFor="Name">You wish to connect as*</label>
                                <select className="inpt" name="connectAs" onChange={(e) => { handleChange(e); handleFieldChange(e) }}>
                                    <option>Please Select</option>
                                    <option value='Job Seeker'>Job Seeker</option>
                                    <option value='Employer'>Employer</option>
                                </select>
                            </div>
                            <div className="inptBlock col-md-5 mt-4"></div>
                            {/* {console.log('current value of selected stuff ' + user)} */}
                            {user == "Job Seeker" ? <Employee formData={formData} handleFieldChange={handleFieldChange} handleFileChange={handleFileChange} /> : ""}
                            {user == "Employer" ? <Employer formData={formData} handleFieldChange={handleFieldChange} /> : ""}
                            <div className="recaptcha">
                                <ReCAPTCHA
                                    sitekey="6LdkxdUqAAAAAA-qfIv8E4HZkD74nfCgXdtz-Heq"
                                    onChange={(value) => setCaptchaValue(value)}
                                />
                            </div>
                            <div className="text-center pt-3 btndiv"><button className="submitBtn" type="submit">Submit</button></div>
                        </div>
                    </form>
                </div>
            </section>
            <Map />
            <Footer />
        </>
    )
}



