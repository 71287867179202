import { useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import { Header } from "./Header.js";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Footer } from "./Footer.js";
import './automobile.css'
import './services.css'
import './industries.css'
import ServiceTypes from "./ServiceTypes.jsx";
import Cards from "./Cards.jsx"
import { Testimonial } from "./Testimonial.js";

export const Automobile = () => {
    useEffect(() => {
        AOS.init();
        document.title = "Right Careers - automobile industry";
    }, [])

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const ref = useRef()

    return (
        <>
            <Header />
            {/* banner section */}
            <div className="bg-img services_bg_auto">
                <div className="bdcrumb"><a href="/">Home</a> | Industries | Automobile / Auto components </div>
                <span className="sm-highlight">Automobile / Auto components </span>
                <h1 data-aos="fade-right" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine" className="headline-text text-white">Driving Talent for  <br /><span className="subhead" style={{ fontWeight: 400 }}>Tomorrow’s Auto Innovations </span></h1>
                <div className="text-center custom-icon"><IoIosArrowDown onClick={handleClick} className='custom-typography' /></div>
            </div>
            {/* about overview section on home page */}
            <section className="clients my-5" ref={ref}>
                <div className="industry headings">
                    <h2 data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="500" style={{ fontWeight: 800 }} className="mx-auto industrial mytext">The automobile and auto component industry thrives on<br /> innovation, requiring skilled professionals adept at adapting to technological advancements and evolving consumer demands.</h2>
                    <p data-aos="fade-up" data-aos-duration="500" className="mt-3">Right Careers offers specialized recruiting services tailored to the ever-evolving demands of the automobile and auto component sector. We understand that innovation drives this industry, and finding the right talent is crucial to staying competitive.</p>
                    <p data-aos="fade-up" data-aos-duration="1000" className="mt-3">From engineers and designers to production specialists and supply chain experts, our team excels in identifying and placing professionals who fuel progress and ensure operational excellence. Leveraging our deep industry insights and cutting-edge recruitment strategies, we help companies build teams that push the boundaries of automotive innovation.
                        With a focus on both skillsets and cultural fit, we deliver high-calibre candidates who are ready to shape the future of automobile and auto components.</p>
                </div>
            </section>
            <section className="indSrvc pt-5">
                <h3 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" className="text-center sectTitle">Our Services</h3>
                <div className="container pt-4">
                    <div className="row">
                        <div className="col-md-6">
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon1.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Talent Acquisition</h5>
                                    <p>Identifying and attracting top candidates to fit organizational roles.&nbsp;&nbsp;</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon2.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Executive Search</h5>
                                    <p>Locating senior-level leaders with vision and strategic expertise.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon3.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Temporary Staffing</h5>
                                    <p>Providing flexible, short-term talent solutions for immediate business needs.</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon4.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Recruitment Process
                                        Outsourcing (RPO)</h5>
                                    <p>Managing the entire recruitment process, ensuring efficiency and quality hires.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 right  rghtSect">
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon5.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Employer Branding</h5>
                                    <p>Enhancing company reputation to attract and retain top talent.</p>
                                </div>
                            </div>
                            <img data-aos="fade-right" data-aos-duration="1000" className="lady" src={require('./img/industrial_serivce.webp')} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="staffing py-5">
                <h3 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" className="text-center sectTitle">Our Staffing Solutions</h3>
                <div className="container py-4">
                    <div className="cards">
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/auto1.webp')} alt="" />
                            <div className="innertexts">
                                <h5>Engineering Roles</h5>
                                <p>
                                    - Automobile Design Engineer<br />
                                    - Vehicle Dynamics Engineer<br />
                                    - Electrical and Electronics Engineer<br />
                                    - Powertrain Engineer<br />
                                    - Mechanical Engineer<br />
                                    - Battery Engineer (for EVs)
                                </p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/auto2.webp')} alt="" />
                            <div className="innertexts">
                                <h5>Manufacturing & Production</h5>
                                <p>
                                    - Manufacturing Engineer<br />
                                    - QC / QA Engineer<br />
                                    - Production Supervisor / Manager<br />
                                    - Process Improvement Engineer<br />
                                    - Tooling Engineer<br />
                                    - Automation Engineer

                                </p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/auto3.webp')} alt="" />
                            <div className="innertexts">
                                <h5>Research & Development (R&D)</h5>
                                <p>
                                    - R&D Engineer<br />
                                    - Autonomous Vehicle Engineer<br />
                                    - EV Systems Engineer<br />
                                    - Sustainability Engineer
                                </p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/auto4.webp')} alt="" />
                            <div className="innertexts">
                                <h5>Sales & Marketing</h5>
                                <p>
                                    - Automobile Sales Executive / Manager<br />
                                    - Product Manager<br />
                                    - Brand Manager<br />
                                    - Market Research Analyst<br />
                                    - Digital Marketing Manager<br />
                                    - Automotive After-Sales Service Manager
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="clients py-5">
                <h3 data-aos="fade-up" data-aos-easing="ease-in-sine" className="text-center sectTitle">Some of Our Clients</h3>
                <div className="text-center">
                    <img data-aos="zoom-in" data-aos-duration="1000" className="pt-3" style={{ width: 300 }} src={require('./img/tatamotors.webp')} alt="" />
                </div>
            </section>
            <Testimonial />
            <section className="indstats">
                <video className='visionBg' autoPlay muted loop>
                    <source src={require('./video/Indvid.mp4')} type="video/mp4" />
                </video>
                <div className="visionContent indRows py-5">
                    <p data-aos="fade-right" data-aos-duration="500"><span className="boldText">5K+</span> Successful<br /> Recruits Yearly</p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="1000">Assured <span className="boldText">2:2</span><br />Hiring Plan </p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="1500">Consistent Track record of<br /><span className="boldText">95%</span> On-Time Delivery</p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="2000" className="nobr"><span className="boldText">Skill-Based Training</span><br /> with Certified Industry Experts</p>
                </div>
            </section>
            <Footer />
        </>
    )
}