import { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import { Header } from "./Header";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './joinus.css';
import BasicExample from "./BasicExample.jsx";
import { Footer } from "./Footer";
import 'react-phone-number-input/style.css';
import axios from 'axios';
import { FaSpinner } from "react-icons/fa";

import PhoneInput from 'react-phone-number-input';
import ReCAPTCHA from "react-google-recaptcha";

export const JoinUs = () => {
    const [value, setValue] = useState()
    const [captchaValue, setCaptchaValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        lastname: '',
        mob: '',
        email: '',
        job_title: '',
        file: null,
    });
    const [responseMessage, setResponseMessage] = useState('');
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handlePhoneChange = (value) => {
        setFormData({ ...formData, mob: value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, file: e.target.files[0] });
    };

    useEffect(() => {
        AOS.init();
        document.title = "Right Careers - join us";
    }, [])

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const ref = useRef()

    const handleSubmit = async (e) => {
        e.preventDefault()
        // if (!captchaValue) {
        //     alert("Please verify the CAPTCHA");
        //     return;
        // }
        if (!formData.mob || formData.mob.length < 7) {
            alert('Please enter a valid contact number.');
            return;
        }
        setLoading(true);

        // Send data to backend
        // console.log("CAPTCHA Verified:", captchaValue);

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('lastname', formData.lastname);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('mob', formData.mob);
        formDataToSend.append('job_title', formData.job_title);
        if (formData.file) {
            formDataToSend.append('file', formData.file);
        }

        try {
            const response = await axios.post('https://rc-red.vercel.app/api/upload', formDataToSend, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            setResponseMessage('Form submitted successfully!');
            if (response.status === 200) {
                setResponseMessage('Form submitted successfully! Thank you for applying.');
                setFormData({
                    name: '',
                    lastname: '',
                    email: '',
                    mob: '',
                    job_title: '',
                    file: null,
                });
            } else {
                setResponseMessage('Something went wrong. Please try again later.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setResponseMessage('Server error. Please try again.');
        }

        finally {
            setLoading(false); // Stop loading after API call
        }
    }
    return (
        <>
            <Header />
            <div className="banner bg-img-join">
                <div className="bdcrumb"><Link to="/">Home</Link> | <Link to="#">About</Link> | Join Us</div>
                <span className="sm-highlight"> Join Us</span>
                <h1 data-aos="fade-right" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine" className="headline-text text-white">We are Who We Hire</h1>
                <div className="text-center custom-icon"><IoIosArrowDown onClick={handleClick} className='custom-typography' /></div>
            </div>
            <section ref={ref} style={{ backgroundColor: '#ef8f22' }} className='p-5 w80 sect-overview joinSect'>
                <h3 data-aos="fade-up" data-aos-easing="ease-in-sine" className='container text-center dark content'>
                    At Right Careers, we foster a dynamic and inclusive<br /> culture where every team member thrives.
                </h3>
            </section>
            <section className="values py-5">
                <h3 data-aos="fade-up" className="text-center pb-4 mb-3 sectTitle">Our Values</h3>
                <div data-aos="fade-up" className="container valuesSect">
                    <div data-aos="fade-right" data-aos-duration="800" className="text-center boxes px-5">
                        <img src={require('./img/icons-inner-page/integrity.png')} alt="Right careers services" />
                        <h3 className="text-center">Integrity</h3>
                    </div>
                    <div Integrity data-aos-duration="800" className="text-center boxes px-5">
                        <img src={require('./img/icons-inner-page/innovation.png')} alt="Right careers services" />
                        <h3 className="text-center">Innovation</h3>
                    </div>
                    <div data-aos="fade-right" data-aos-duration="800" className="text-center boxes px-5">
                        <img src={require('./img/icons-inner-page/collabration.png')} alt="Right careers services" />
                        <h3 className="text-center">Collaboration</h3>
                    </div>
                </div>
                <div className="container py-2">
                    <p data-aos="fade-up" data-aos-duration="1000" className="text-center">We believe in transparent communication, empowering leadership, and a workplace prioritizing professional growth and personal well-being.</p>
                    <p data-aos="fade-up" data-aos-duration="1200" className="text-center">Employees are encouraged to engage fully, contribute creatively, and grow through continuous learning. With a focus on diversity, inclusion, and work-life balance, Right Careers offers a fulfilling career path, where talent is nurtured and opportunities for advancement are always within reach.</p>
                </div>
            </section>
            <seciton className="vision join flex-column d-none d-md-flex">
                <video className='visionBg' autoPlay muted loop>
                    <source src={require('./video/join.mp4')} type="video/mp4" />
                </video>
                <div className="visionContent">
                    <h3 data-aos="fade-up" data-aos-easing="ease-in-sine" className="text-center pb-4 sectTitle">Our Investment is You</h3>
                    <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-sine">As a people-centric organization, your well-being is our top priority—physically, mentally, professionally, financially, and socially. We go beyond offering perks and benefits by providing access to the resources you need to live and work in a way that motivates you to excel for yourself and those around you.</p>
                </div>
            </seciton>
            <seciton className="vision join d-sm-flex d-md-none flex-column">
                <div className="visionContent">
                    <h3 data-aos="fade-up" data-aos-easing="ease-in-sine" className="text-center pb-4 sectTitle">Our Investment is You</h3>
                    <p data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-sine">As a people-centric organization, your well-being is our top priority—physically, mentally, professionally, financially, and socially. We go beyond offering perks and benefits by providing access to the resources you need to live and work in a way that motivates you to excel for yourself and those around you.</p>
                </div>
            </seciton>
            <section className="faq">
                <div className="container py-5">
                    <h3 className="text-center pb-4 mb-3 sectTitle">Where Values Drive Success</h3>
                    <BasicExample />
                </div>
            </section>
            <section data-aos="zoom-in-up" data-aos-offset="300" data-aos-duration="1000" className="form py-5 d-none d-md-block">
                <div className="container formContainer py-4">
                    <h3 className="text-center mb-3 sectTitle"> Join us to be part of a culture that <br /> values your potential and promotes success.</h3>
                    <div className="text-center">
                        <form className="text-center joinUs" onSubmit={handleSubmit} action="">
                            <input placeholder="Name" className="inpt" type="text" name="name" value={formData.name} onChange={handleChange} required/>
                            <input placeholder="Last Name" className="inpt" type="text" name="lastname" value={formData.lastname} onChange={handleChange} required/>
                            <input placeholder="Email Id" className="inpt" type="text" name="email" value={formData.email} onChange={handleChange} required/>
                            <PhoneInput
                                country={'us'}
                                international
                                defaultCountry="IN"
                                value={formData.mob}
                                onChange={handlePhoneChange}
                                required
                            />
                            <input placeholder="Job Title Applied For" className="inpt" type="text" name="job_title" value={formData.job_title} onChange={handleChange} required/>
                            <div className="inpt fileUpload">
                                <span>Upload Resume <input placeholder="Job Title Applied For" className="file" type="file" onChange={handleFileChange} required /></span>
                            </div>
                            <div className="recaptcha">
                                <ReCAPTCHA
                                    sitekey="6LdkxdUqAAAAAA-qfIv8E4HZkD74nfCgXdtz-Heq"
                                    onChange={(value) => setCaptchaValue(value)}
                                />
                            </div>
                            <button className="submitBtn" type="submit" disabled={loading}>Submit {loading && <FaSpinner className="spinner-icon" />}</button>
                            {responseMessage && <p className="responseMessage">{responseMessage}</p>}
                        </form>
                    </div>
                </div>
            </section>
            <section className="form py-5 d-sm-block d-md-none">
                <div className="container formContainer py-4">
                    <h3 className="text-center mb-3 sectTitle"> Join us to be part of a culture that <br /> values your potential and promotes success.</h3>
                    <div className="text-center">
                        <form className="text-center joinUs" onSubmit={handleSubmit} action="">
                            <input placeholder="Name" className="inpt" type="text" name="name" value={formData.name} onChange={handleChange} />
                            <input placeholder="Last Name" className="inpt" type="text" name="lastname" value={formData.lastname} onChange={handleChange} />
                            <input placeholder="Email Id" className="inpt" type="text" name="email" value={formData.email} onChange={handleChange} />
                            <PhoneInput
                                country={'us'}
                                international
                                defaultCountry="IN"
                                value={formData.mob}
                                onChange={handlePhoneChange}
                                required
                            />
                            <input placeholder="Job Title Applied For" className="inpt" type="text" name="job_title" value={formData.job_title} onChange={handleChange} />
                            <div className="inpt fileUpload"><span>Upload Resume <input placeholder="Job Title Applied For" className="file" type="file" onChange={handleFileChange} /></span></div>
                            <div style={{ margin: '0 auto' }}>
                                <ReCAPTCHA
                                    sitekey="6LdkxdUqAAAAAA-qfIv8E4HZkD74nfCgXdtz-Heq"
                                    onChange={(value) => {
                                        console.log("Captcha value:", value); // Add this for debugging
                                        setCaptchaValue(value);
                                    }}
                                />
                            </div>
                            <button className="submitBtn" type="submit" disabled={loading}>Submit {loading && <FaSpinner className="spinner-icon" />}</button>
                            
                            {responseMessage && <p className="responseMessage">{responseMessage}</p>}
                        </form>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}