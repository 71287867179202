import { useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import { Header } from "./Header.js";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Footer } from "./Footer.js";
import './services.css'
import './info.css'
import './industries.css'
import ServiceTypes from "./ServiceTypes.jsx";
import Cards from "./Cards.jsx"
import { Testimonial } from "./Testimonial.js";

export const Information_Technology = () => {
    useEffect(() => {
        AOS.init();
        document.title = "Right Careers - information technology industry";
    }, [])

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const ref = useRef()

    return (
        <>
            <Header />
            {/* banner section */}
            <div className="bg-img services_bg_info">
                <div className="bdcrumb"><a href="/">Home</a> | Industries | Information Technology</div>
                <span className="sm-highlight">Information Technology</span>
                <h1 data-aos="fade-right" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine" className="headline-text text-white">Powering the People <br /><span className="subhead" style={{ fontWeight: 400 }}>Behind Global Giants  </span></h1>
                <div className="text-center custom-icon"><IoIosArrowDown onClick={handleClick} className='custom-typography' /></div>
            </div>
            {/* about overview section on home page */}
            <section className="clients my-5" ref={ref}>
                <div className="industry headings">
                    <h2 data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="500" style={{ fontWeight: 800 }} className="mx-auto industrial mytext">The IT sector is a driving force behind modern economies, and recruiting top-tier talent is essential for companies to maintain their competitive edge.</h2>
                    <p data-aos="fade-up" data-aos-duration="500" className="mt-3">At Right Careers, our team of skilled professionals is proficient and stays up to date with the latest technology trends to ensure that our clients receive the most innovative and effective solutions. In an ever-competitive landscape, we have a deep understanding of the industry's nuances, and our commitment to providing customized staffing solutions sets us apart as a trusted partner for businesses navigating innovation and growth challenges.
                    </p>
                    <p data-aos="fade-up" data-aos-duration="1000" className="mt-3">Right Careers has solidified its presence as a leading staffing provider in the IT industry, serving its global clientele within this fast-evolving sector. With a proven track record of supporting the top 500 IT companies, Right Careers delivers unparalleled expertise in identifying and placing the right talent in an industry that demands both technical excellence and agility. 
                        </p>
                </div>
            </section>
            <section className="indSrvc pt-5">
                <h3 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" className="text-center sectTitle">Our Services</h3>
                <div className="container pt-4">
                    <div className="row">
                        <div className="col-md-6">
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon1.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Talent Acquisition</h5>
                                    <p>Identifying and attracting top candidates to fit organizational roles.&nbsp;&nbsp;</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon2.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Executive Search</h5>
                                    <p>Locating senior-level leaders with vision and strategic expertise.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon3.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Temporary Staffing</h5>
                                    <p>Providing flexible, short-term talent solutions for immediate business needs.</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon4.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Recruitment Process 
                                    Outsourcing (RPO)</h5>
                                    <p>Managing the entire recruitment process, ensuring efficiency and quality hires.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 right  rghtSect">
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon5.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Employer Branding</h5>
                                    <p>Enhancing company reputation to attract and retain top talent.</p>
                                </div>
                            </div>
                            <img data-aos="fade-right" data-aos-duration="1000" className="lady" src={require('./img/industrial_serivce.webp')} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="staffing py-5">
                <h3 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" className="text-center sectTitle">Our Staffing Solutions</h3>
                <div className="container py-4">
                    <div className="cards">
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/it1.jpg')} alt="" />
                            <div className="innertexts">
                                <h5>Automobile Design & Engineering</h5>
                                <p>
                                    - Automobile Design Engineer<br />
                                    - Vehicle Dynamics Engineer<br />
                                    - Electrical and Electronics Engineer<br />
                                    - Powertrain Engineer<br />
                                    - Mechanical Engineer<br />
                                    - Battery Engineer (for EVs)
                                </p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/it2.jpg')} alt="" />
                            <div className="innertexts">
                                <h5>Manufacturing & Production</h5>
                                <p>
                                    - Manufacturing Engineer<br />
                                    - Quality Control / Quality Assurance (QA) Engineer<br />
                                    - Production Supervisor / Manager<br />
                                    - Process Improvement Engineer<br />
                                    - Tooling Engineer<br />
                                    - Automation Engineer
                                </p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/it3.jpg')} alt="" />
                            <div className="innertexts">
                                <h5>Automotive Research and Development (R&D)</h5>
                                <p>
                                    - R&D Engineer<br />
                                    - Autonomous Vehicle Engineer<br />
                                    - EV Systems Engineer<br />
                                    - Sustainability Engineer
                                </p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/it4.jpg')} alt="" />
                            <div className="innertexts">
                                <h5>Automobile Sales and Marketing</h5>
                                <p>
                                    - Automobile Sales Executive / Manager<br />
                                    - Product Manager<br />
                                    - Brand Manager<br />
                                    - Market Research Analyst<br />
                                    - Digital Marketing Manager<br />
                                    - Automotive After-Sales Service Manager
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="clients py-5">
                <h3 data-aos="fade-up" data-aos-easing="ease-in-sine" className="text-center sectTitle">Some of Our Clients</h3>
                <div className="text-center">
                    <img data-aos="zoom-in" data-aos-duration="1000" className="pt-3" style={{ width: 300 }} src={require('./img/industry_clients/IT.webp')} alt="" />
                    <img data-aos="zoom-in" data-aos-duration="1000" className="pt-3" style={{ width: 300 }} src={require('./img/industry_clients/IT-1.webp')} alt="" />
                    <img data-aos="zoom-in" data-aos-duration="1000" className="pt-3" style={{ width: 300 }} src={require('./img/industry_clients/IT-2.webp')} alt="" />
                </div>
            </section>
            <Testimonial />
            <section className="indstats">
                <video className='visionBg' autoPlay muted loop>
                    <source src={require('./video/Indvid.mp4')} type="video/mp4" />
                </video>
                <div className="visionContent indRows py-5">
                    <p data-aos="fade-right" data-aos-duration="500"><span className="boldText">5K+</span> Successful<br /> Recruits Yearly</p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="1000">Assured <span className="boldText">2:2</span><br />Hiring Plan </p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="1500">Consistent Track record of<br /><span className="boldText">95%</span> On-Time Delivery</p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="2000" className="nobr"><span className="boldText">Skill-Based Training</span><br /> with Certified Industry Experts</p>
                </div>
            </section>
            <Footer />
        </>
    )
}