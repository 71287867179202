import { Footer } from "./Footer"
import { Header } from "./Header"

export const Coockies = () => {
  // const style = {
  //   backgroundColor: '#e5dfd9',
  // }

  return <>
    <Header />
    <div class="the_content_wrapper container-fluid">
      <h3 className="text-center sectTitle py-4">Cookies Policy</h3>
      This Cookies Policy explains what cookies are, how Right Careers uses cookies, and your choices regarding cookies on our website, <a class="custom-link" href="https://www.rightcareers.co.in/ ">https://www.rightcareers.co.in/ </a> (the “Website”).<p><strong>What Are Cookies?</strong></p><p>Cookies are small files placed on your device by a website. They are widely used to make websites work efficiently and to provide reporting information. Cookies allow websites to remember your actions and preferences (such as login details, language, and other settings) over a period of time, so you don’t have to re-enter them whenever you return to the site or browse from one page to another.</p><p><strong>Types of Cookies We Use</strong></p><ol class="wp-block-list"><li><strong>Necessary Cookies</strong>: These cookies are essential for the Website to function properly. They enable core functionality such as security, network management, and accessibility. Without these cookies, the Website would not be able to function correctly.</li>

      <li><strong>Performance Cookies</strong>: These cookies help us understand how visitors interact with our Website by collecting and reporting information on how the Website is used. This helps us improve the Website’s performance and user experience.</li>

      <li><strong>Functional Cookies</strong>: Functional cookies allow the Website to remember your choices and preferences to provide a more personalized experience. For example, they can remember your login details or language preference.</li>

      <li><strong>Targeting/Advertising Cookies</strong>: These cookies are used to deliver advertisements that are relevant to you and your interests. They may also limit the number of times you see an advertisement and help measure the effectiveness of advertising campaigns.</li></ol><p><strong>How We Use Cookies</strong></p><p>Right Careers uses cookies for various purposes, including:</p><ul class="wp-block-list"><li>To ensure the proper functioning of the Website.</li>

        <li>To analyze Website traffic and performance.</li>

        <li>To personalize your experience on our Website.</li>

        <li>To serve you relevant advertisements and measure their effectiveness.</li></ul><p><strong>Third-Party Cookies</strong></p><p>In addition to our own cookies, we may also use third-party cookies to help us with the services mentioned above. For example, we may use analytics services to track and analyze the use of our Website. These third parties may place cookies on your device to collect information about your interactions with our Website.</p><p><strong>Managing Cookies</strong></p><p>You have the right to decide whether to accept or reject cookies. You can manage cookies through your browser settings. Most web browsers allow you to control cookies through their settings preferences. You can usually set your browser to block cookies or to notify you when cookies are sent. Please note that if you choose to block cookies, some parts of the Website may not function properly.</p><p>For more information about how to manage cookies on various browsers, you can visit the following links:</p><ul class="wp-block-list"><li>Google Chrome</li>

        <li>Mozilla Firefox</li>

        <li>Microsoft Edge</li>

        <li>Safari</li></ul><p><strong>Changes to This Cookies Policy</strong></p><p>We may update this Cookies Policy from time to time to reflect changes in technology, legal requirements, or our practices. We encourage you to review this policy periodically to stay informed about how we use cookies.</p></div>
    <Footer />
  </>
}