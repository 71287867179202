import { useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import { Header } from "./Header.js";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Footer } from "./Footer.js";
import './services.css'
import './hospitality.css'
import './industries.css'
import ServiceTypes from "./ServiceTypes.jsx";
import Cards from "./Cards.jsx"
import { Testimonial } from "./Testimonial.js";

export const Hospitality = () => {
    useEffect(() => {
        AOS.init();
        document.title = "Right Careers - Hospitality industry";
    }, [])

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const ref = useRef()

    return (
        <>
            <Header />
            {/* banner section */}
            <div className="bg-img services_bg_hosp">
                <div className="bdcrumb"><a href="/">Home</a> | Industries | Hospitality, FMCG and Food Processing </div>
                <span className="sm-highlight">Hospitality, FMCG and Food Processing </span>
                <h1 data-aos="fade-right" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine" className="headline-text text-white">Serving the Right Talent to   <br /><span className="subhead" style={{ fontWeight: 400 }}>Savour Success </span></h1>
                <div className="text-center custom-icon"><IoIosArrowDown onClick={handleClick} className='custom-typography' /></div>
            </div>

            {/* about overview section on home page */}
            <section className="clients my-5" ref={ref}>
                <div className="industry headings">
                    <h2 data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="500" style={{ fontWeight: 800 }} className="mx-auto industrial mytext">In the dynamic sectors of Hospitality, Fast-Moving Consumer Goods (FMCG), and Food Processing, effective recruitment is essential for ensuring success and sustainability. These industries are characterized by rapid changes, high consumer demand, and intense competition, necessitating a workforce that is not only skilled but also adaptable. 
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="500" className="mt-3">FMCG, or Fast-Moving Consumer Goods, encompasses everyday essentials like packaged foods, beverages, toiletries, and household items that fly off the shelves. This vibrant sector thrives on constant innovation, requiring companies to adapt quickly to ever-changing consumer trends. At Right Careers, we understand the pulse of the FMCG industry and specialize in connecting these companies with exceptional talent. Whether it’s in product development, marketing, supply chain management, or customer service, our recruitment strategies are designed to build dynamic teams that drive innovation and execute impactful marketing campaigns, ensuring businesses stay competitive and relevant.
                    </p>
                    <p data-aos="fade-up" data-aos-duration="500" className="mt-3">In the Hospitality and Food Processing sectors, the stakes are equally high, with a diverse range of skills needed—from culinary artistry to top-notch customer service and strict quality control. At Right Careers, we pride ourselves on finding the right professionals who not only meet these demands but also share a passion for delivering exceptional service. These dedicated individuals play a crucial role in enhancing customer satisfaction and fostering brand loyalty.
                    </p>
                    <p data-aos="fade-up" data-aos-duration="500" className="mt-3">As consumer preferences evolve and sustainability takes centre stage, the importance of effective recruitment cannot be overstated. We believe in focusing on candidates who bring not just the technical expertise but also a genuine commitment to innovation and excellence. With the growing demand for quality products and services, partnering with Right Careers for recruitment becomes essential for organizations eager to thrive in the exciting realms of Hospitality, FMCG, and Food Processing. Together, we can build teams that not only meet expectations but exceed them, making a real difference in people's lives every day.                      
                    </p>     
                </div>
            </section>
            <section className="indSrvc pt-5">
                <h3 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" className="text-center sectTitle">Our Services</h3>
                <div className="container pt-4">
                    <div className="row">
                        <div className="col-md-6">
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon1.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Talent Acquisition</h5>
                                    <p>Identifying and attracting top candidates to fit organizational roles.&nbsp;&nbsp;</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon2.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Executive Search</h5>
                                    <p>Locating senior-level leaders with vision and strategic expertise.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon3.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Temporary Staffing</h5>
                                    <p>Providing flexible, short-term talent solutions for immediate business needs.</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon4.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Recruitment Process 
                                    Outsourcing (RPO)</h5>
                                    <p>Managing the entire recruitment process, ensuring efficiency and quality hires.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 right  rghtSect">
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon5.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Employer Branding</h5>
                                    <p>Enhancing company reputation to attract and retain top talent.</p>
                                </div>
                            </div>
                            <img data-aos="fade-right" data-aos-duration="1000" className="lady" src={require('./img/industrial_serivce.webp')} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="staffing py-5">
                <h3 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" className="text-center sectTitle">Our Staffing Solutions</h3>
                <div className="container py-4">
                    <div className="cards">
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/hospitality1.jpg')} alt="" />
                            <div className="innertexts">
                                <h5>Marketing & Sales</h5>
                                <p>
                                    - FMCG Brand Managers<br />
                                    - Trade Marketing Specialists<br />
                                    - Sales Executives/Representatives<br />
                                    - Area Sales Managers
                                </p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/hospitality2.jpg')} alt="" />
                            <div className="innertexts">
                                <h5>Staffing Solutions for Supply Chain and Distribution</h5>
                                <p>
                                    - Supply Chain Managers<br />
                                    - Logistics Coordinators<br />
                                    - Warehouse Managers
                                </p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/hospitality3.jpg')} alt="" />
                            <div className="innertexts">
                                <h5>Staffing Solutions for Product Development and Innovation</h5>
                                <p>
                                    - Product Development Managers<br />
                                    - R&D Scientists<br />
                                    - Quality Assurance Managers<br />
                                    - Packaging Designers
                                </p>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </section>
            <section className="clients py-5">
                <h3 data-aos="fade-up" data-aos-easing="ease-in-sine" className="text-center sectTitle">Some of Our Clients</h3>
                <div className="text-center">
                    <img data-aos="zoom-in" data-aos-duration="1000" className="pt-3" style={{ width: 300 }} src={require('./img/industry_clients/healthcare.webp')} alt="" />
                </div>
            </section>
            <Testimonial />
            <section className="indstats">
                <video className='visionBg' autoPlay muted loop>
                    <source src={require('./video/Indvid.mp4')} type="video/mp4" />
                </video>
                <div className="visionContent indRows py-5">
                    <p data-aos="fade-right" data-aos-duration="500"><span className="boldText">5K+</span> Successful<br /> Recruits Yearly</p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="1000">Assured <span className="boldText">2:2</span><br />Hiring Plan </p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="1500">Consistent Track record of<br /><span className="boldText">95%</span> On-Time Delivery</p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="2000" className="nobr"><span className="boldText">Skill-Based Training</span><br /> with Certified Industry Experts</p>
                </div>
            </section>
            <Footer />
        </>
    )
}