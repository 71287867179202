import { useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import { Header } from "./Header.js";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Footer } from "./Footer.js";
import './healthcare.css'
import './services.css'
import './industries.css'
import ServiceTypes from "./ServiceTypes.jsx";
import Cards from "./Cards.jsx"
import { Testimonial } from "./Testimonial.js";

export const Healthcare = () => {
    useEffect(() => {
        AOS.init();
        document.title = "Right Careers - Healthcare industry";
        
    }, [])

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const ref = useRef()

    return (
        <>
            <Header />
            {/* banner section */}
            <div className="bg-img services_bg_heal">
                <div className="bdcrumb"><a href="/">Home</a> | Industries | Healthcare & Pharma </div>
                <span className="sm-highlight"> Healthcare & Pharma </span>
                <h1 data-aos="fade-right" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine" className="headline-text text-white">Talent that Heals, <br /><span className="subhead" style={{ fontWeight: 400 }}>sourced by Teams that Care  </span></h1>
                <div className="text-center custom-icon"><IoIosArrowDown onClick={handleClick} className='custom-typography' /></div>
            </div>
            {/* about overview section on home page */}
            <section className="clients my-5" ref={ref}>
                <div className="industry headings">
                    <h2 data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="500" style={{ fontWeight: 800 }} className="mx-auto industrial mytext">Right Careers offers specialized staffing solutions tailored for the Medical and Healthcare industry, connecting organizations with top-tier talent essential for delivering quality care. Our expertise spans various roles, from healthcare professionals and medical technicians to administrative support and pharmaceutical specialists. 
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="500" className="mt-3">With a keen understanding of industry demands, we focus on finding candidates who not only possess the necessary skills but also share a commitment to improving patient outcomes. Our streamlined recruitment process ensures that healthcare organizations can efficiently build strong, capable teams that enhance their operational effectiveness and foster innovation.
                    </p>
                     
                </div>
            </section>
            <section className="indSrvc pt-5">
                <h3 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" className="text-center sectTitle">Our Services</h3>
                <div className="container pt-4">
                    <div className="row">
                        <div className="col-md-6">
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon1.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Talent Acquisition</h5>
                                    <p>Identifying and attracting top candidates to fit organizational roles.&nbsp;&nbsp;</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon2.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Executive Search</h5>
                                    <p>Locating senior-level leaders with vision and strategic expertise.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon3.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Temporary Staffing</h5>
                                    <p>Providing flexible, short-term talent solutions for immediate business needs.</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon4.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Recruitment Process 
                                    Outsourcing (RPO)</h5>
                                    <p>Managing the entire recruitment process, ensuring efficiency and quality hires.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 right  rghtSect">
                            <div data-aos="fade-right" data-aos-duration="500" className="indcards">
                                <div>
                                    <img src={require('./rcindustryicons/icon5.png')} alt="" />
                                </div>
                                <div className="ps-4">
                                    <h5>Employer Branding</h5>
                                    <p>Enhancing company reputation to attract and retain top talent.</p>
                                </div>
                            </div>
                            <img data-aos="fade-right" data-aos-duration="1000" className="lady" src={require('./img/industrial_serivce.webp')} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="staffing py-5">
                <h3 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" className="text-center sectTitle">Our Staffing Solutions</h3>
                <div className="container py-4">
                    <div className="cards">
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/health_care1.jpg')} alt="" />
                            <div className="innertexts">
                                <h5>Medical Device Design & Development</h5>
                                <p>
                                    - Biomedical Engineer<br />
                                    - Mechanical Engineer (Medical Devices)<br />
                                    - Electrical Engineer (Medical Devices)<br />
                                    - Embedded Systems Engineer<br />
                                    - Software Developer (Medical Devices)<br />
                                    - Product Manager (Medical Devices)
                                </p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" className="staff card1">
                            <img src={require('./industries_imgs/health_care2.jpg')} alt="" />
                            <div className="innertexts">
                                <h5>Medical Equipment Testing and Quality Assurance</h5>
                                <p>
                                    - Quality Assurance (QA) Engineer (Medical Devices)<br />
                                    - Test Engineer (Medical Devices)<br />
                                    - Validation Engineer<br />
                                    - Regulatory Affairs Specialist (Medical Devices)
                                </p>
                            </div>
                        </div>
                 
                    </div>
                </div>
            </section>
            <section className="clients py-5">
                <h3 data-aos="fade-up" data-aos-easing="ease-in-sine" className="text-center sectTitle">Some of Our Clients</h3>
                <div className="text-center">
                    <img data-aos="zoom-in" data-aos-duration="1000" className="pt-3" style={{ width: 300 }} src={require('./img/industry_clients/healthcare-1.webp')} alt="" />
                    <img data-aos="zoom-in" data-aos-duration="1000" className="pt-3" style={{ width: 300 }} src={require('./img/industry_clients/healthcare.webp')} alt="" />
                </div>
            </section>
            <Testimonial />
            <section className="indstats">
                <video className='visionBg' autoPlay muted loop>
                    <source src={require('./video/Indvid.mp4')} type="video/mp4" />
                </video>
                <div className="visionContent indRows py-5">
                    <p data-aos="fade-right" data-aos-duration="500"><span className="boldText">5K+</span> Successful<br /> Recruits Yearly</p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="1000">Assured <span className="boldText">2:2</span><br />Hiring Plan </p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="1500">Consistent Track record of<br /><span className="boldText">95%</span> On-Time Delivery</p><p className="line"></p>
                    <p data-aos="fade-right" data-aos-duration="2000" className="nobr"><span className="boldText">Skill-Based Training</span><br /> with Certified Industry Experts</p>
                </div>
            </section>
            <Footer />
        </>
    )
}