import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";


const CookieModal = ({ storageType }) => {
  const [isAccepted, setIsAccepted] = useState(false);

  useEffect(() => {
    if (storageType === "cookie") {
      const cookieConsent = document.cookie
        .split("; ")
        .find((row) => row.startsWith("cookieConsent="))
        ?.split("=")[1];
      if (cookieConsent === "true") {
        setIsAccepted(true);
      }
    } else {
      const localStorageConsent = localStorage.getItem("cookieConsent");
      if (localStorageConsent === "true") {
        setIsAccepted(true);
      }
    }
  }, [storageType]);

  const acceptCookies = () => {
    if (storageType === "cookie") {
      document.cookie = "cookieConsent=true; path=/; max-age=" + 60 * 60 * 24 * 365;
    } else {
      localStorage.setItem("cookieConsent", "true");
    }
    setIsAccepted(true);
  };

  if (isAccepted) return null; // Hide modal if already accepted

  return (
    <div className="cookie-modal position-fixed bottom-0 start-0 m-3 p-3 rounded shadow-lg">
      <p className="mb-2">
        🍪 We use cookies to improve your experience. By continuing, you agree to our use of cookies.
      </p>
      <button className="btn btn-dark btn-sm" onClick={acceptCookies}>
        Accept All
      </button>
      <button className="btn btn-dark btn-sm" onClick={acceptCookies}>
        Reject All
      </button>
    </div>
  );
};

export default CookieModal;
